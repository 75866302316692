//RESPONSIVE SLIDER
/*
	Archivos:
		_slidersResponsive.scss
		sliderResponsive.js

	Requiere:
		Bootstrap 3 CSS
		JQuery 1.12 >
		GSAP TweenMax,
	params
	{
	    target  	: string selector "#tardiv"
	    next    	: string selector "#tardiv"
	    prev    	: string selector "#tardiv"
	    tiempoAuto  : segundos. 0 no hay movimiento.
	    show		: 1
	    endless		: true
	    lastCallback: callback reach last item
	    PARAMETROS MODIFICADOS POR BREACKPOINTS:
	    breakpoints:{
            "sm":{
                show:1,
                tiempoAuto: 3
            },
            "xs":{
                show:2
            },
            "xxs":{
                show:1
            }
        }
	}

*/
var SliderResponsive = function(cfg){
	this.DEBUG = false;
	this.PRELOAD_IMAGES = false;

	this.cfg = null;
	this.tar = null;

	this.slides = null;
	this.slidesWidth = null;
	this.slideWidth = null;
	this.slidesWidthPixels = null;

	this.puntos = null;

	this.slidesPositions = null;
	this.activeSlide = 0;
	this.totalSlides = 0;
	this.middleSlide = 0;

	this.autoTimeout = null;
	this.disablePageScroll = true;

	this.activeCfg = "default";

	//preload
	this.currImageLoaded = 0;
	this.totalImages = 0;

	//callbacks
	this.lastCallback = undefined;

	//this canvas

	this.destroy = function(){

		if(this.autoTimeout) clearTimeout(this.autoTimeout);
		if(this.slideContainer) TweenMax.killTweensOf(this.slideContainer);

		this.cfg = null;
		this.tar = null;

		this.slides = null;
		this.slidesWidth = null;
		this.slideWidth = null;
		this.slidesWidthPixels = null;

		this.puntos = null;

		this.slidesPositions = null;
	}

	this.initResponsive = function(){

		this.updateSizes();

		if(this.DEBUG){
			this.slides.each(function(index, item){
				$(item).append("<h1 style='position:absolute;top:0px;left:0px;background:#fff; color:#000; font-size:50px;'>"+index+"</h1>");
			});
		}

		//height del slider
		var that = this;
		this.updateHeight();

		if(this.PRELOAD_IMAGES){
			
			this.currImageLoaded = 0;
			this.totalImages = this.tar.find("img").length;
			this.tar.find("img").each(function(index, item){
				var img = new Image();
				img.onload = function(){
					that.onImageLoaded(that);
				}
				img.onerror = function(){
					that.onImageLoaded(that);
				}
				img.src = $(item).attr("src");
			});
		}else{
			this.tar.find("img").each(function(index, item){
				var img = new Image();
				img.onload = function(){
					that.updateHeight(that);
				}

				img.src = $(item).attr("src");
			});
			this.onImagesReady();
		}
	}

	this.updateSizes = function(){
		//medidas
		this.slidesWidth = (100*this.totalSlides)/this.cfg.show;
		this.slideWidth = 100/this.totalSlides;

		//tamaños de contenedores
		this.tar.css({"width": "100%", "position":"relative"});
		this.slides.css({"width":this.slideWidth+"%", "position":"absolute"});
		this.slideContainer.css({"width":this.slidesWidth+"%"});
		this.tar.find(".sr-image").addClass("img-responsive");
	}

	this.onImageLoaded = function(that){
		that.currImageLoaded++;
		that.updateHeight(that);
		if(that.currImageLoaded >= that.totalImages){
			that.onImagesReady();
		}
	}

	this.onImagesReady = function(){
		this.slides.css({"visibility":"visible"});
		var that = this;
		//extras
		if(this.cfg.puntos && this.totalSlides != this.cfg.show){
			var ptdiv = $("<div class='sr-navPuntosContainer'></div>");
			if(this.cfg.puntosContainer){
				this.cfg.puntosContainer.append(ptdiv);
			}else{
				$(this.cfg.target).append(ptdiv);
			}
			for(var i=0; i<this.totalSlides; i++){
				ptdiv.append("<a href='#' class='sr-navPuntos' data-id='"+i+"'></a>");
			}
			var that = this;
			this.puntos = ptdiv.find(".sr-navPuntos");
			this.puntos.click(function(){
				that.movToItem($(this).data("id"), true);
				return false;
			});
		}
		if(this.cfg.flechas && this.totalSlides != this.cfg.show){
			var arrowRight = $("<div class='sr-navArrowsContainer-right'><a href='#' class='sr-arrow sr-arrowRight'></a></div>");
			var arrowLeft = $("<div class='sr-navArrowsContainer-left'><a href='#' class='sr-arrow sr-arrowLeft'></a></div>");
			$(this.cfg.target).append(arrowRight);
			$(this.cfg.target).append(arrowLeft);
			arrowRight.find(".sr-arrowRight").click(function(){
				that.navNext();
				return false;
			});
			arrowLeft.find(".sr-arrowLeft").click(function(){
				that.navPrev();
				return false;
			});
		}
		if(this.cfg.next){
			if(this.totalSlides != this.cfg.show){
				$(this.cfg.next).click(function(){
					that.navNext();
					return false;
				});
			}else{
				TweenMax.set(this.cfg.next, {autoAlpha:0});
			}
		}
		if(this.cfg.prev){
			if(this.totalSlides != this.cfg.show){
				$(this.cfg.prev).click(function(){
					that.navPrev();
					return false;
				});
			}else{
				TweenMax.set(this.cfg.prev, {autoAlpha:0});
			}
		}

		//si tiene algun tipo de evento de navegación
		if((this.cfg.flechas || this.cfg.puntos)  && this.totalSlides != this.cfg.show){
			var dragging, initposMouse, initposCont, deltax;
			var that = this;
			var fromDragging = false;
			var mdown = function(evt) {
				if(TweenMax.isTweening($(this))) return false;
			    dragging = true;
			    initposCont = that.slideContainer.position().left;
			    initposMouse = evt.originalEvent.touches[0].pageX;
			    $(this).on("touchmove", mousemove);
			    $(document).on("touchend", mup);
			}
			var mousemove = function(evt) {
		    	deltax = evt.originalEvent.changedTouches[0].pageX-initposMouse;
		    	if(Math.abs(deltax)>30){
		    		//is dragging
		    		fromDragging = true;
		    		evt.preventDefault();
		    		TweenMax.to(that.slideContainer, 0.1, {"left":initposCont+deltax });
		    		//prevent click
		    		that.slideContainer.click(function(){return false;})
		    	}
			}
			var mup = function(evt){
				dragging = false;
				//unbind events
			    that.slideContainer.unbind("touchmove");
			    $(document).unbind("touchend");
			    $(window).unbind('touchmove');
			    that.slideContainer.unbind("click");

			    //evt.preventDefault();
			    var limit = that.slidesWidthPixels/4;
			    if(deltax>limit){
			    	that.navPrev(that);
			    }else if(deltax<(limit*-1)){
			    	that.navNext(that);
			    }else{
			    	that.movToItem(that.activeSlide, true);
			    }

			    if(fromDragging){
			    	evt.stopPropagation();
			    	fromDragging = false;
			    }
			}
			//jquery events
			this.slideContainer.on("touchstart", mdown);
			//acomodar flechas
			this.onResize();


		}
		if(this.totalSlides == this.cfg.show){
			this.middleSlide = 0;
		}
		// posiciones
		this.onCompleteMovement();
	}

	this.navNext = function(){
		var nextSlide = that.activeSlide+1;
		if(nextSlide >= that.totalSlides) nextSlide = 0;
		that.navToNext(nextSlide);
	}
	this.navPrev = function(){
		var nextSlide = that.activeSlide-1;
		if(nextSlide < 0) nextSlide = that.totalSlides-1;
		that.navToPrev(nextSlide);
	}

	this.onResize = function(){
		var winsize = APP.getWinVisibleSize().width;
		var active=this;
		this.slidesWidthPixels = Math.ceil(this.tar.width()/this.cfg.show);
		//switch cfg
		if(active.cfg.breakpoints){
			if(active.cfg.xxs && winsize<active.cfg.xxs && active.cfg.breakpoints.xxs){
				active.parseCfg(active.cfg.breakpoints.xxs, "xxs");
			}else if(active.cfg.xs && winsize<active.cfg.xs && active.cfg.breakpoints.xs){
				active.parseCfg(active.cfg.breakpoints.xs, "xs");
			}else if(active.cfg.sm && winsize<active.cfg.sm && active.cfg.breakpoints.sm){
				active.parseCfg(active.cfg.breakpoints.sm, "sm");
			}else if(active.cfg.md && winsize<active.cfg.md && active.cfg.breakpoints.md){
				active.parseCfg(active.cfg.breakpoints.md, "md");
			}else{
				active.parseCfg(active.defaultCfg, "default");
			}
		}
		//switch images
		active.images.each(function(index, item){
			if(active.cfg.xxs && winsize<active.cfg.xxs && $(item).data("xxs")){
				$(item).attr("src", $(item).data("xxs"));
			}else if(active.cfg.xs && winsize<active.cfg.xs && $(item).data("xs")){
				$(item).attr("src", $(item).data("xs"));
			}else if(active.cfg.sm && winsize<active.cfg.sm && $(item).data("sm")){
				$(item).attr("src", $(item).data("sm"));
			}else if(active.cfg.md && winsize<active.cfg.md && $(item).data("md")){
				$(item).attr("src", $(item).data("md"));
			}else{
				$(item).attr("src", $(item).data("lg"));
			}
		});
		this.updateHeight();
	}

	this.parseCfg = function(cfg, id){
		if(this.activeCfg == id) return;

		$.extend( this.cfg, cfg );
		
		this.slidesWidthPixels = Math.ceil(this.tar.width()/this.cfg.show);
		this.updateSizes();
		this.reArrangePositions(this);
		this.movToItem(this.activeSlide, false);

		//show and timeout
		if(this.totalSlides>this.cfg.show){
			clearTimeout(this.autoTimeout);
			var that = this;
			this.autoTimeout = setTimeout(function(){
				that.navNext();
			}, this.cfg.tiempoAuto*1000);
		}else{
			clearTimeout(this.autoTimeout);
			this.movToItem(0, false);
		}
		this.activeCfg = id;
	}

	this.updateHeight = function(scope){
		var maxhh = 0;
		var that = this;
		if(scope) that = scope;
		that.slides.each(function(index,item){
			if($(item).height()>maxhh){
				maxhh = $(item).height();
			}
		});;
		that.tar.css({"height":maxhh+"px"});
		if(this.cfg.flechas){
			$(this.cfg.target+" .sr-navArrowsContainer-right").css({"height": maxhh+"px"});
			$(this.cfg.target+" .sr-navArrowsContainer-left").css({"height": maxhh+"px"});
		}
	}

	//fixes wrong navigation
	this.navToNext = function(tarId){
		var tarIndex = $.inArray(this.activeSlide, this.slidesPositions);
		var lastVisibleItem = tarIndex+this.cfg.show;
		if(lastVisibleItem>=this.totalSlides){
			that.middleSlide = this.totalSlides-this.cfg.show-1;
			this.reArrangePositions(this);
		}
		this.movToItem(tarId, true);
	}
	this.navToPrev = function(tarId){
		var tarIndex = $.inArray(this.activeSlide, this.slidesPositions);
		var firstItem = tarIndex-1;
		if(firstItem<=0){
			that.middleSlide = 1;
			this.reArrangePositions(this);
		}
		this.movToItem(tarId, true);
	}

	this.reArrangePositions = function(scope){
		/*
		acomodamos con el activo en el centro
		posiciones: [0][1][2][3]
		activo: 	3
		valores:	[2][3][0][1]
		*/
		var that = this;
		if(scope) that = scope;
		if(!that.cfg.endless){
			for(var i = 0; i<that.slidesPositions.length; i++){
				that.slidesPositions[i] = i;
			}
		}else{
			for(var i = 0; i<that.slidesPositions.length; i++){
				//update posiciones de control
				tarid = that.activeSlide-that.middleSlide+i;
				if(tarid<0) tarid = that.totalSlides-tarid*-1;
				if(tarid>=that.totalSlides) tarid = (that.totalSlides-tarid)*-1;
				that.slidesPositions[i] = tarid;
			}
		}
		var slidesPosX = [];
		for(var i = 0; i<that.slidesPositions.length; i++){
			var posx = i*that.slidesWidthPixels;
			slidesPosX.push(posx);
			$(that.slides.get(that.slidesPositions[i])).css({"left":posx+"px"});
		}
		that.movToItem(that.activeSlide, false);
	}

	this.updateNav = function(that){ 
		if(this.activeSlide == (this.totalSlides - 1) && this.cfg.lastCallback) this.cfg.lastCallback();
		if(!that.cfg.puntos || !that.puntos) return;
		that.puntos.each(function(index, item){
			if(index == that.activeSlide){
				$(item).addClass("sr-active");
			}else{
				$(item).removeClass("sr-active");
			}
		});
	}

	this.getPositionByIndex = function(id){
		for(var i = 0; i<this.slidesPositions.length; i++){
			if(this.slidesPositions[i] == id) return i;
		};
		return null;
	}

	this.onCompleteMovement = function(scope){
		that = this;
		if(scope) that = scope;
		that.reArrangePositions(that);
		clearTimeout(that.autoTimeout);
		if(that.cfg.tiempoAuto && (that.cfg.show < that.totalSlides)){
			that.autoTimeout = setTimeout(function(){
				that.navNext();
			}, that.cfg.tiempoAuto*1000);
		}
		if(that.cfg.changeCallback) that.cfg.changeCallback(that.activeSlide, $(that.slides.get(that.activeSlide)));
	}

	this.movToItem = function(tarid, animated){
		var tarPos = $(this.slides.get(tarid)).position().left;
		if(animated){
			var tt = Math.abs(this.slideContainer.position().left-(tarPos*-1))*0.001;
			if(tt<0.3)tt = 0.3;
			if(tt>2) tt = 2;
			clearTimeout(that.autoTimeout);
			TweenMax.killTweensOf(this.slideContainer);
			//if(this.cfg.changeCallback) this.cfg.changeCallback(this.tarid, $(this.slides.get(this.tarid)));
			TweenMax.to(this.slideContainer, tt, {left:tarPos*-1, onComplete:this.onCompleteMovement, onCompleteParams:[this]});
			if(this.cfg.toChangeCallback) this.cfg.toChangeCallback(tarid, $(this.slides.get(that.tarid)));
		}else{
			TweenMax.set(this.slideContainer, {left:tarPos*-1});
		}
		this.activeSlide = tarid;
		this.updateNav(this);
	}

	//CONSTRUCTOER
	//add events
	var that = this;
	$(window).resize(function(){
		that.onResize();
	});

	this.cfg = cfg

	//items to show
	if (!this.cfg.show) {
		this.cfg.show = 1;
	}

	//clone default cfg
	this.defaultCfg = {};
	$.extend( this.defaultCfg, this.cfg );

	//set up items
	$(this.cfg.target).css({"position":"relative"});
	this.tar = $(this.cfg.target+" .sr-container");
	this.slides = $(this.cfg.target+" .sr-slide");
	this.images = $(this.cfg.target+" .sr-image");
	this.slideContainer = $(this.cfg.target+" .sr-slides");

	//control vars
	this.totalSlides = this.slides.length;
	this.middleSlide = Math.floor(this.totalSlides/2);
	this.slidesPositions = new Array(this.totalSlides);

	//first resize
	this.onResize();
	$(window).resize(function(){
		that.reArrangePositions();
	});
	//init
	this.slides.css({"visibility":"hidden"});
	this.initResponsive();
}

module.exports = SliderResponsive;