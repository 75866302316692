require("../../scss/home.scss");
import SliderResponsive from '../componentes/SliderResponsive.js';

var home = function(){
	
	var heroSlider = null;
	var detallesSlider = null;
	var ratioVidoContainer = 1;

	var constructor = function(){
		//sliders
		heroSlider = new SliderResponsive({
            target:"#hero_galeria",
            tiempoAuto:4,
            flechas:false,
            md:550,
            puntos:true,
            puntosContainer: $("#puntosContainer"),
            changeCallback: slideChange,
            toChangeCallback: slideToChange
        });

        //equal rows
		$('.hero .equalRow .equalCol').matchHeight();

		//resize init
		resizeWin();
	}

	var slideChange = function(index, value){
		TweenMax.staggerTo(["#slideContent h2", "#slideContent p", "#slideContent .cta"], 0.2, {autoAlpha:0, x:10, onComplete:function(){
			if(value.attr("data-titulo")){
				$("#slideContent h2").css({"display": "block"}).html(value.attr("data-titulo"));
			}else{
				$("#slideContent h2").css({"display": "none"});
			}
			if(value.attr("data-bajada")){
				$("#slideContent p").css({"display": "block"}).html(value.attr("data-bajada"));
			}else{
				$("#slideContent p").css({"display": "none"});
			}
			if(value.attr("data-ctalink")){
				$("#clickCapture").css({display:"block"}).attr("href", value.attr("data-ctalink")).attr("target", value.attr("data-ctalinktarget"));
			}else{
				$("#clickCapture").css({display:"none"}).attr("href", value.attr("#"));
				$("#slideContent .cta").css({"display": "none"});
			}
			$.fn.matchHeight._update()
			TweenMax.staggerFromTo(["#slideContent h2", "#slideContent p", "#slideContent .cta"], 0.2, {autoAlpha:0, x:-10}, {autoAlpha:1, x:0, onComplete:function(){
				$.fn.matchHeight._update()
			}});
		}}, 0.05)
		$.fn.matchHeight._update()
	}

	var slideToChange = function(index, value){
		var ifrmaeTemplate = "";
	}

	var resizeWin = function(viewportSize){
		console.log("resize");
		$(".videoContainer").each(function(index, value){
			var parent = $(value).parent();
			console.log(parent);
			console.log(parent.width(), parent.width());
			ratioVidoContainer = parent.width()/parent.height();
			var ratioVideo = 16/9;
			var twidth, theight;
			if(ratioVidoContainer<ratioVideo){
				theight = parent.height();
				twidth = theight * ratioVideo;
			}else{
				twidth = parent.width();
				theight = twidth / ratioVideo;
			}
			var tleft = (parent.width() - twidth)/2
			var ttop = (parent.height() - theight)/2
			$(value).css({width: twidth, height: theight, left: tleft, top: ttop});
		});
	}

	$(document).ready(constructor);
	$(window).resize(resizeWin);
}

home();